.creations {
  width: 100%;
  min-height: 100vh;
  background-color: var(--color-4);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.creations h1, .creations p {
  padding: var(--section-padding-vertical) var(--section-padding-horizontal);
}

.creations h1 {
  color: var(--color-2);
  font-family: Rachella,Calibri,Arial,Serif;
  font-size: 3.5em;
}

.creations p {
  color: var(--color-2);
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.5;
}

.creations button {
  margin:1rem auto;
  border: 3px solid var(--color-3);
  border-radius: 999px;
  padding: 1rem 2rem;
  background-color: var(--color-2);
  font-size: 1.25rem;
}

.creations button:hover {
  border: 1px solid var(--color-2);
  transform: scale(1.1);
  transition: transform .3s ease-in-out, border .3s ease-in-out;
}