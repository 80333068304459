/*
* used with preprocessors and contain font, colors definitions, etc.
*/
@import './settings.css';

/*
*  used with preprocessors and contain font, colors definitions, etc.
*/
@import './tools.css';

/*
* reset and/or normalize styles, box-sizing definition, etc.
* This is the first layer which generates actual CSS.
*/
@import './generic.css';

/* 
* styling for bare HTML elements (like H1, A, etc.). These come with default
* styling from the browser so we can redefine them here.
*/
@import './elements.css';

/* 
* class-based selectors which define undecorated design patterns, 
* for example the media object known from OOCSS
*/
@import './objects.css';

/*
* specific UI components. This is where most of our work takes place.
* We often compose UI components of Objects and Components
*/
@import './components.css';

/*
* utilities and helper classes with ability to override anything which
* goes before in the triangle, e.g. hide helper class
*/
@import './utilities.css';
