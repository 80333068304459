@import './normalize.css';

body {
  position: absolute;
  width: 100%;
  font-family: var(--font-standard), Calibri, Arial, Serif;
}

a {
  color: var(--color-9)
}