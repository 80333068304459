#creations-gallery, #courses-gallery {
  width: 100%;
  display: grid;
  object-fit: cover;
  grid-template: 1fr / repeat(1, 1fr);
  place-items: center;
  padding: var(--section-padding-vertical) var(--section-padding-horizontal);
  grid-gap: .5rem;
  grid-auto-flow: row dense;

}

@media only screen and (min-width: 481px) {
  #creations-gallery, #courses-gallery {
    grid-template: 1fr / repeat(2, 1fr);
  }
}

@media only screen and (min-width: 1024px) {
  #creations-gallery, #courses-gallery {
    grid-template: 1fr / repeat(4, 1fr);
  }
}

#creations-gallery img, #courses-gallery img {
  width: 100%;
  border: .5rem solid var(--color-2);
}

