.retouches {
  width: 100%;
  min-height: 100vh;
  background-color: var(--color-4);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: 5rem;
}

.retouches h1, .retouches p {
  padding: var(--section-padding-vertical) var(--section-padding-horizontal);
}

.retouches h2 {
  padding: 0 var(--section-padding-horizontal) var(--section-padding-vertical) var(--section-padding-horizontal);
}

.retouches h1 {
  color: var(--color-2);
  font-family: Rachella,Calibri,Arial,Serif;
  font-size: 3.5em;
}

.retouches h2 {
  color: var(--color-2);
  font-family: Rachella,Calibri,Arial,Serif;
  font-size: 1.25rem;
}

.retouches p {
  color: var(--color-2);
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.5;
}

.retouches table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  max-width: 60rem;
  margin:auto;
  table-layout:fixed;
}

.retouches table tr td[colspan="2"] {
  background-color: var(--color-2);
  color: var(--color-9);
}

.retouches table tr {
  line-height: 2;
}

.retouches table tr td {
  color: var(--color-2);
  padding-left: 0.5rem;
}