

@font-face {
  font-family: Rachella;
  src: url('/assets/fonts/Rachella.woff');
}

:root {
  --font-standard: calibri;
  --font-title: Rachella;

  --color-1: #F8BBA7;
  --color-2: #FFFFFF;
  --color-3: #0C224A;
  --color-4: #3a4e72;
  --color-5: #FFFFFF;
  --color-6: #DDDDDD;
  --color-7: #FE4E50;
  --color-8: #3273DC;
  --color-9: #4a4a4a;
  --color-10: #C70039;

  --section-padding-horizontal: 4rem;
  --section-padding-vertical: 2rem;
}