.about {
  width: 100%;
  min-height: 100vh;
  background-color: var(--color-2);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.about h1, .about p {
  padding: var(--section-padding-vertical) var(--section-padding-horizontal);
}

.about h1 {
  color: var(--color-4);
  font-family: Rachella,Calibri,Arial,Serif;
  font-size: 3.5em;
}

.about p {
  color: var(--color-9);
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.5;
}