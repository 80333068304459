.infos {
  width: 100%;
  min-height: 100vh;
  background-color: var(--color-2);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.infos h1 {
  padding: var(--section-padding-vertical) var(--section-padding-horizontal);
  color: var(--color-4);
  font-family: Rachella,Calibri,Arial,Serif;
  font-size: 3.5em;
}

.infos h2 {
  padding: 0 var(--section-padding-horizontal) var(--section-padding-vertical) var(--section-padding-horizontal);
  color: var(--color-4);
  font-family: Rachella,Calibri,Arial,Serif;
  font-size: 1.25rem;
}

.infos p {
  color: var(--color-9);
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 0 var(--section-padding-horizontal);
}

.infos__map {
  padding: 0 var(--section-padding-horizontal);
  width: 50rem;
}

.infos__socials {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(4, 1fr);
}

.infos__socials img {
  height: 1.75rem;
}