.banner {
  width: 100%;
  height: 100vh;
  background-image: url('../../img/banner.jpg');
  background-position: fixed;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner__logo {
  height: 20rem;
}

.banner__logo img{
  height: 100%;
}