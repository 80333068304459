.footer {
  background-color: var(--color-4);
  height: 20rem;
  width: 100%;
  margin-top: 8rem;
  position: relative;
}

.footer__logo {
  width: 15rem;
  position: absolute;
  top: -5.4rem;
  left: 50%;
  transform: translateX(-50%);
}