.header {
  position: fixed;
  background-color: var(--color-2);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: .5rem;
  height: 50px;
  width: 100%;
}

.header__logo {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 1.5rem;
}

.header__logo img {
  height: 100%;
  height: 1.75rem;
}

.header__socials {
  height: 100%;
  display: grid;
  column-gap: .5rem;
  align-items: center;
  grid-template-columns: repeat(4, 1fr);
}

.header__socials img {
  height: 1.75rem;
}

.header__menu {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 1.5rem;
}

@media only screen and (max-width: 481px) {
  .header__menu {
    position:fixed;
    right:0;
    top: 0;
    height: 100vh;
    width: 100vw;
    z-index: 100;
    display: flex;
    flex-direction: column;
    padding-right: 0;
    background-color: var(--color-2);
  }
}

.header__menu ul {
  list-style-type: none;
  display: inline-flex;
  column-gap: 1rem;
}

@media only screen and (max-width: 481px) {
  .header__menu ul {
    display: initial;
    list-style-type: none;
  }
}

.header__menu ul li a {
  color: var(--color-7);
  text-decoration: none;
  cursor: pointer;
}

.header__menu ul li a:hover {
  color: var(--color-8);
}
